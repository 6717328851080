<template>
  <b-modal
      v-model="onControl"
      :title="title"
      title-tag="h3"
      modal-class="modal-primary"
      @hidden="close"
      size="lg"
      :hide-footer="!isEditing"
      no-close-on-backdrop
  >
    <div class="d-block">
      <validation-observer ref="form">
        <b-container>
            <validation-provider
                v-slot="{ errors, valid }"
                name="account_type"
                rules="required"
            >
              <b-form-group
                  label="Account type:"
                  label-for="input-1"
              >
                <div v-bind:class="isEditing ? 'g-account-type' : '' ">
                  <b-skeleton v-if="!finishedLoading" type="input"></b-skeleton>
                  <v-select
                      v-else
                    :disabled = "!isEditing"
                    @input="onChangeAccountType"
                    v-model="category.accountType"
                    label="description"
                    :options="accountTypes"
                    :reduce="description => description.id"
                ></v-select>
                  <div class="align-self-center ml-1" v-if="isEditing">
                    <feather-icon
                        v-if="!showPlusAccount"
                        class="pointer text-primary"
                        icon="PlusCircleIcon"
                        size="18"
                        @click="showAccountFields"
                    />
                    <feather-icon
                        v-if="showPlusAccount"
                        class="pointer text-primary"
                        icon="MinusCircleIcon"
                        size="18"
                        @click="hideAccountFields"
                    />
                  </div>
                </div>
                <span v-if="errors[0]" class="text-danger">
                    Account type {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>
          <validation-observer ref="accountForm" v-if="showPlusAccount">
            <validation-provider
                v-slot="{ errors, valid }"
                name="account_type_description"
                :rules="rules"
            >
              <b-form-group
                  label="Account type Description:"
                  label-for="input-7"
              >
                <div style="display: grid; grid-template-columns: 13fr 4fr">
                  <b-form-input
                      v-model="accountTypeObject.description"
                      type="text"
                  ></b-form-input>
                  <div class="d-flex align-items-center ml-1">
                    <b-button variant="outline-info" @click="saveAccountType(1)">Save Account Type</b-button>
                  </div>
                </div>
                <span v-if="errors[0]" class="text-danger">
                    Account type Description {{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </validation-observer>
            <validation-provider
                v-slot="{ errors, valid }"
                name="detail_type"
                rules="required"
            >
              <b-form-group
                  label="Detail type:"
                  label-for="input-2"
              >
                <div
                    v-bind:class="isEditing ? 'g-detail-type' : '' "
                >
                <b-skeleton v-if="!finishedLoading" type="input"></b-skeleton>
                <v-select
                    v-if="!isSecondTime && finishedLoading"
                    v-model="category.detailType"
                    :disabled = "!isEditing"
                    label="description"
                    :options="accountTypesDetail"
                    :reduce="description => description.id"
                ></v-select>
                <v-select
                    v-if="isSecondTime && finishedLoading"
                    v-model="categoryObject"
                    @input="onChangeDetailTypeObject"
                    :disabled = "!isEditing"
                    label="description"
                    :options="accountTypesDetail"
                ></v-select>
                  <div class="align-self-center ml-1" v-if="isEditing">
                    <feather-icon
                        v-if="!showPlusDetail"
                        class="pointer text-primary"
                        icon="PlusCircleIcon"
                        size="18"
                        @click="showDetailFields"
                    />
                    <feather-icon
                        v-if="showPlusDetail"
                        class="pointer text-primary"
                        icon="MinusCircleIcon"
                        size="18"
                        @click="hideDetailFields"
                    />
                  </div>
                </div>
                <span v-if="errors[0]" class="text-danger">
                    Detail type {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>
          <validation-observer ref="accountTypeDetailForm" style="display: grid; grid-template-columns: 7fr 10fr" v-if="showPlusDetail">
            <validation-provider
                v-slot="{ errors, valid }"
                name="account_type_detail"
                :rules="rules"
            >
              <b-form-group
                  label="Account type Detail:"
                  label-for="input-9"
              >
                <b-form-input
                    v-model="accountTypeDetailObject.description"
                    type="text"
                    required
                ></b-form-input>
                <span v-if="errors[0]" class="text-danger">
                    Account type Detail {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>
            <validation-provider
                v-slot="{ errors, valid }"
                name="account_type_mini_form"
                :rules="rules"
            >
              <b-form-group
                  label="Account type:"
                  label-for="input-10"
                  class="ml-1"
              >
                <div style="display: grid; grid-template-columns: 7fr 3fr" >
                <v-select
                    v-model="accountTypeDetailObject.parent_id"
                    label="description"
                    :options="accountTypes"
                    :reduce="description => description.id"
                ></v-select>
                  <div class="d-flex align-items-center ml-1">
                    <b-button variant="outline-info"  @click="saveAccountType(2)">Save Detail</b-button>
                  </div>
                </div>
                <span v-if="errors[0]" class="text-danger">
                    Account type {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>

          </validation-observer>
          <b-form-group
              label="Name:"
              label-for="input-3"
          >
            <b-skeleton v-if="!finishedLoading" type="input"></b-skeleton>
            <b-form-input
                v-else
                v-model="category.name"
                type="text"
                required
                disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group
              label="Description:"
              label-for="input-4"
          >
            <b-skeleton-wrapper :loading="!finishedLoading">
              <template #loading>
                <b-skeleton height="60px" type="input"></b-skeleton>
              </template>
              <b-form-textarea :disabled = "!isEditing" id="textarea-default" v-model="category.description"/>
            </b-skeleton-wrapper>
          </b-form-group>
        </b-container>
      </validation-observer>
    </div>
    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()"></button-cancel>
      <button-save @click="saveCategory"></button-save>
    </template>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import buttonSave from '@/views/commons/utilities/ButtonSave.vue'
import buttonCancel from '@/views/commons/utilities/ButtonCancel.vue'
import CategoriesService from "@/views/management/views/categories/categories.service";
import { mapGetters } from 'vuex'

export default {
  components: {
    vSelect,
    buttonSave,
    buttonCancel,
  },
  props: {
    item: null,
    mood: null,
  },
  data(){
    return {
      onControl: false,
      selectedText: '',
      accountTypes: [],
      accountTypesDetail: [],
      parentAccounts: [],
      category: {
        accountType: 0,
        detailType: '',
        name: '',
        description: '',
        isSubAccount: false,
        parentAccount: {
          id: null,
        },
      },
      accountTypeObject: {
        description: null,
        parent_id: null,
      },
      accountTypeDetailObject: {
        description: '',
        parent_id: '',
      },
      categoryObject: {
        description: null,
        id: null,
      },
      selected: '',
      showPlusAccount: false,
      showPlusDetail: false,
      isEditing: true,
      title: '',
      finishedLoading: false,
      isSecondTime: false,
      rules: "required",
    }
  },
  async created(){
    if (this.mood === 1){
      this.isEditing = false
      this.title = "Read Category"
    }else {
      this.isEditing = true
      this.title = "Edit Category"
    }
    // this.$store.commit('app/SET_LOADING', true)
    this.finishedLoading = false
    this.category.description = this.item.description
    this.category.name = this.item.name
    this.category.isSubAccount = this.item.is_sub_account
    this.category.parentAccount.id = this.item.parent_account
    this.onControl = true
    await this.getAccountTypes()
    this.category.accountType = this.item.account_type
    await this.getAccountTypesDetail(this.category.accountType)
    this.category.detailType = this.item.detail_type
    // this.$store.commit('app/SET_LOADING', false)
    this.finishedLoading = true
    // await this.getParentAccounts()
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async getAccountTypesDetail(parentId){
      this.accountTypesDetail = await CategoriesService.getAccountTypesDetail({parent_id: parentId})
    },
    async getAccountTypes(){
      this.accountTypes = await CategoriesService.getAccountTypes()
    },
    async saveAccountType(type){
      if (type === 1) {
        try {
          const result = await this.$refs.accountForm.validate();
          if (result) {
            const response = await this.showConfirmSwal();
            if (response.isConfirmed) {
              this.accountTypeObject.parent_id = null
              const data = await CategoriesService.saveAccountType({
                description: this.accountTypeObject.description,
                parent_id: this.accountTypeObject.parent_id,
              })

              await this.getAccountTypes()
              if (data.status === 200) {
                this.showSuccessSwal("Account Type "+ this.accountTypeObject.description + " has been added successfully")
                this.showPlusAccount = false
                this.accountTypeObject.description = null
                this.accountTypeObject.parent_id = null
              }
            }
          }
        } catch (e){
          this.showErrorSwal(e);
        }
      }  else {
        try {
          const result = await this.$refs.accountTypeDetailForm.validate();
          if (result) {
            const response = await this.showConfirmSwal();
            if (response.isConfirmed) {
              const data =  await CategoriesService.saveAccountType({
                description: this.accountTypeDetailObject.description,
                parent_id: this.accountTypeDetailObject.parent_id,
              })
              if(this.accountTypeDetailObject.parent_id === this.category.accountType){
                await this.getAccountTypesDetail(this.accountTypeDetailObject.parent_id)
              }

              if (data.status === 200) {
                this.showSuccessSwal("Account Type Detail "+ this.accountTypeDetailObject.description + " has been added successfully");
                this.showPlusDetail = false
                this.accountTypeDetailObject.description = null
                this.accountTypeDetailObject.parent_id = null
              }
            }
          }
        } catch (e){
          this.showErrorSwal(e);
        }
      }
    },
    async saveCategory(){
      try {
        this.rules=''
        this.showPlusAccount = false
        this.showPlusDetail = false
        const result = await this.$refs.form.validate();
        if (result) {
          const response = await this.showConfirmSwal();
          if (response.isConfirmed) {
            const data = await CategoriesService.saveCategory({
                  account_type: this.category.accountType,
                  detail_type: this.category.detailType,
                  name: this.category.name,
                  description: this.category.description,
                  is_sub_account: this.category.isSubAccount,
                  parent_account: this.item.parent_account,
                  t_type: this.item.type,
                  created_by: this.currentUser.user_id,
                  t_category_id: this.item.id,
                }
            )
            if (data.status === 200) {
              this.showSuccessSwal("Category has been updated successfully");
              this.close();
              this.rules = 'required'
              this.$emit('reload')
            }
          }
        }
      }catch (e) {
        this.showErrorSwal(e);
      }
    },
    showAccountFields(){
      this.showPlusAccount = true
    },
    hideAccountFields(){
      this.showPlusAccount = false
      this.accountTypeObject.description = ''
    },
    showDetailFields(){
      this.showPlusDetail = true
    },
    hideDetailFields(){
      this.showPlusDetail = false
      this.accountTypeDetailObject.description = ''
      this.accountTypeDetailObject.parent_id = ''
    },
    onChangeAccountType(){
      this.getAccountTypesDetail(this.category.accountType)
      this.category.detailType = ''
      this.category.name = ''
      this.categoryObject = ''
      this.isSecondTime = true
    },
    onChangeDetailTypeObject(){
      this.category.name = this.categoryObject.description
      this.category.detailType = this.categoryObject.id
    }
  },
}
</script>

<style scoped>
.bigger {
  width: 2em;
  height: 2em;
}
.g-detail-type{
  display: grid;
  grid-template-columns: 12fr .5fr
}
.g-account-type{
  display: grid;
  grid-template-columns: 12fr .5fr
}
</style>
