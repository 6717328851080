<template>
  <div>
      <header-slot>
        <template #actions>
          <div>
            <b-button variant="success" @click="openCreateModal">
              <feather-icon
                  icon="PlusIcon"
                  size="15"
                  class="mr-50 text-white"
              />Create
            </b-button>
          </div>
        </template>
      </header-slot>
    <filter-slot
        :filter="[]"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refCategoryClients'].refresh()"
    >
      <template #table>
        <b-table
        ref="refCategoryClients"
        no-border-collapse
        class="position-relative"
        :fields="fields"
        show-empty
        no-provider-filtering
        sticky-header="50vh"
        primary-key="id"
        responsive="sm"
        :items="categoriesProvider"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :filter="searchInput"
        :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(actions)="data">
            <feather-icon
                class="mr-1 pointer text-primary"
                icon="EyeIcon"
                size="15"
                @click="openEditWatchModal(1, data.item)"
            />
            <feather-icon
                class="mr-1 pointer text-warning"
                icon="EditIcon"
                size="15"
                @click="openEditWatchModal(2, data.item)"
            />
            <feather-icon
                class="mr-1 pointer text-danger"
                icon="TrashIcon"
                size="15"
                @click="deleteCategory(data.item.id)"
            />
          </template>
          <template #cell(parent_account)="data">
            <span v-if="data.item.id == data.item.parent_account">-</span>
            <span v-else>Sub account of {{data.item.parent_account_text}}</span>
          </template>
          <template #cell(created_by)="data">
            {{ data.item.created_by }} ({{ data.item.created_at | myGlobalWithHour }})
          </template>
        </b-table>
        <create-category-modal
            v-if="openCreateModalController"
            @close="closeCreateModal"
            @reload="$refs['refCategoryClients'].refresh()"
        />
        <edit-watch-category-modal
            v-if="openEditWatchModalController"
            @close="closeEditWatchModal"
            @reload="$refs['refCategoryClients'].refresh()"
            :mood = "mood"
            :item = "selectedItem"
        />
      </template>
    </filter-slot>
  </div>
</template>

<script>
import CategoriesService from "@/views/management/views/categories/categories.service";
import { mapGetters, mapState } from 'vuex'
import createCategoryModal from '@/views/management/views/categories/categories-module/modals/CreateCategoryModal'
import editWatchCategoryModal from '@/views/management/views/categories/categories-module/modals/EditWatchCategoryModal'
export default {
  components: {
    createCategoryModal,
    editWatchCategoryModal,
  },
  data() {
    return {
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Name of category...',
        model: '',
      },
      fields: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'account_type_text',
          label: 'Type',
        },
        {
          key: 'detail_type_text',
          label: 'Detail',
        },
        {
          key: 'description',
          label: 'Description',
        },
        {
          key: 'parent_account',
          label: 'Sub Account',
        },
        {
          key: 'created_by',
          label: 'Created By',
        },
        {
          key: 'actions',
          label: 'Actions',
          thStyle: {
            width: '150px',
          },
        },
      ],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      selectedItem: [],
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: 'name',
      sortDesc: true,
      searchInput: '',
      openCreateModalController: false,
      openEditWatchModalController: false,
      mood: '',
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
  },
  methods: {
    openCreateModal() {
      this.openCreateModalController = true
    },
    openEditWatchModal(mood, item) {
      this.selectedItem = item
      this.openEditWatchModalController = true
      this.mood = mood
    },
    closeCreateModal() {
      this.openCreateModalController = false
    },
    closeEditWatchModal() {
      this.openEditWatchModalController = false
    },
    async saveAccountType() {
      await CategoriesService.saveAccountType({})
    },
    async categoriesProvider(ctx) {
      const params = {
        per_page: ctx.perPage,
        orderby: 1,
        page: ctx.currentPage,
        order: ctx.sortDesc == 1 ? 'desc' : 'asc',
        campo: this.filterPrincipal.model,
      }
      const data = await CategoriesService.getCategories(params)
      this.items = data.data
      this.startPage = data.from
      this.paginate.currentPage = data.current_page
      this.paginate.perPage = data.per_page
      this.nextPage = this.startPage + 1
      this.endPage = data.last_page
      this.totalRows = data.total
      this.toPage = data.to
      return this.items || []
    },
    async deleteCategory(id){
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          const data = await CategoriesService.deleteCategory({
            deleted_by: this.currentUser.user_id,
            category_id: id,
          })
          if (data.status === 200) {
            this.showSuccessSwal("Category has been deleted successfully");
          }
          this.$refs.refCategoryClients.refresh()
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  }
}
</script>

<style scoped>
.bigger{
  width: 1.35em !important;
  height: 1.35em !important;
}
.pointer{
  cursor: pointer;
}
</style>
